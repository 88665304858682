
const insterestingSummary = function() {

const { Configuration, OpenAIApi } = require("openai");


const configuration = new Configuration({
  apiKey: process.env.OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const response = openai.createCompletion("text-davinci-002", {
  prompt: "Basic-Fit (BFIT.AS) has been assigned an average rating of “Buy” from the six research firms that are currently covering the firm, Marketbeat reports. Three investment analysts have rated the stock with a hold recommendation and three have issued a buy recommendation on the company. The average 1-year price objective among brokerages that have updated their coverage on the stock in the last year is $43.00.",
  temperature: 0.7,
  max_tokens: 1000,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
});

console.log(response)};


export { insterestingSummary };
