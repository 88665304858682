/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')


// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------


// External imports
import trix from "trix";
import "trix/dist/trix.css";
import 'actiontext';
import "bootstrap";
import $ from 'jquery';
import "../styles/application";
import "moment";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css'; // for React, Vue and Svelte
import { newsCompany } from "./newsapi";
import { dashboardBar } from "./charts";
import { dashboardLine } from "./charts";
import { dashboardPie } from "./charts";
import { chartPolar } from "./charts";
import { chartRadar } from "./charts";
import { cbDelta } from "./apexchart";
import { cbIv } from "./apexchart";
import { priceGraphCb } from "./graphpricecb";
import { dataTable } from "./datatables";
import { cbIndex } from "./datatables";
import { notyfPerformance } from "./notyf";
import { notyfNav } from "./notyf";
import { insterestingSummary } from "./openai";


// Internal imports, e.g:
import "../modules/bootstrap";
import "../modules/theme";
import "../modules/dragula";
import "../modules/feather";
import "../modules/moment";
import "../modules/sidebar";
import "../modules/toastr";
import "../modules/user-agent";
import "../modules/markdown";
import MarkdownIt from 'markdown-it';
import "../modules/apexcharts";
import "../modules/daterangepicker";
import "../modules/datetimepicker";
import "../modules/mask";
import "../modules/validation";
import '../modules/aos';
import '../modules/bigpicture';
import '../modules/dropzone';
import '../modules/flickity';
import '../modules/highlight';
import '../modules/isotope';
import '../modules/jarallax';
import '../modules/map';
import '../modules/modal';
import '../modules/navbar-dropdown';
import '../modules/navbar';
import '../modules/popover';
import '../modules/smooth-scroll';
import '../modules/tooltip';
import '../modules/typed';



// document.addEventListener('turbolinks:load', () => {
//   // Call your functions here, e.g:
//   // initSelect2();
// });

if (document.getElementById("news-feed")) {
  newsCompany();
}

if (document.getElementById("news-feed")) {
  insterestingSummary();
}

if (document.getElementById("datatables-column-search-text-inputs")) {
  dataTable();
}


if (document.getElementById("cbindex")) {
  cbIndex();
}

if (document.getElementById("chartjs-dashboard-bar-devices")) {
  dashboardBar();
}

if (document.getElementById("chartjs-dashboard-line")) {
  dashboardLine();
}

if (document.getElementById("chartjs-dashboard-pie")) {
  dashboardPie();
}

if (document.getElementById("chartjs-polar-area")) {
  chartPolar();
}

if (document.getElementById("chartjs-dashboard-radar")) {
  chartRadar();
}

if (document.getElementById("chartjs-dashboard-radar")) {
  priceGraphCb();
}

if (document.getElementById("prices")) {
  notyfPerformance();
}

if (document.getElementById("nav")) {
  notyfNav();
}

if (document.getElementById("chart")) {
  cbDelta();
}

if (document.getElementById("chartiv")) {
  cbIv();
}

if (document.getElementById("welcome")) {
// Create an instance of Notyf
const notyf = new Notyf({
  duration:5000,
  position: {
    x:'left',
    y:'bottom'},
    dismissible: true,
  });

// Display an success notification
notyf.success('Hello! Hope you are doing well!!');
}


