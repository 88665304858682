import ApexCharts from "apexcharts";


const priceGraphCb = function() {
  const apex = JSON.parse(document.getElementById("chartjs-dashboard-line").dataset.apex);
  console.log(apex);
    fetch(apex)
          .then(response => response.json())
          .then((data) => {
            const emptyArray = [];
         data.forEach (result => {
              const emptyHash = {x: `${result.date}`, y: [parseFloat(result.price)]};
              emptyArray.push(emptyHash);
            });

     const options = {
          series: [{
          name: 'BG Convertible',
          data: emptyArray
        }],
         chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom'
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return (val).toFixed(2);
            },
          },
          title: {
            text: 'Price'
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return (val).toFixed(2)
            }
          }
        }
        };

        const chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();
      });
}

export { priceGraphCb };
