
import { Notyf } from 'notyf';

const notyfPerformance = function() {
if (document.getElementById("prices")) {
const navlast = document.getElementById("prices").dataset.navlast;
const navonemonth = document.getElementById("prices").dataset.navonemonth;
const navendmonth = document.getElementById("prices").dataset.navendmonth;

// Create an instance of Notyf
const notyf = new Notyf({
  duration:7500,
  position: {
    x:'right',
    y:'top'},
    dismissible: true,
  }
  );

// Display an success notification
const perfonemonth = ((navlast/navonemonth) -1)*100;
const perfendmonth = ((navlast/navendmonth) -1)*100;
const perf = ((navlast/1368.23) -1)*100;
notyf.success(`BG Convertible MTD performance is ${perfendmonth.toFixed(2)}% `);
notyf.success(`BG Convertible 1-month performance is ${perfonemonth.toFixed(2)}% `);
notyf.success(`BG Convertible YTD is ${perf.toFixed(2)}% `);
}
};

const notyfNav = function() {
if (document.getElementById("nav")) {
const nav = document.getElementById("prices").dataset.nav;
const navdate = moment(document.getElementById("prices").dataset.navdate).format ('MMMM Do YYYY');
// Create an instance of Notyf
const notyf = new Notyf({
  duration:7500,
  position: {
    x:'left',
    y:'top'
    },
    types: [
    {
      type: 'warning',
      background: 'orange',
      icon: {
        className: 'material-icons',
        tagName: 'i',
        text: 'warning',
        dismissible: true
      }
    },
    {
      type: 'error',
      background: 'indianred',
      duration: 2000,
      dismissible: true
    }
  ]
  });

// Display an success notification
const perf = ((nav/1234.86) -1)*100
notyf.open({
  type: 'warning',
  message: `BG Convertible last NAV: EUR ${nav} as of ${navdate}`
});

}
};

export { notyfPerformance };
export { notyfNav };
