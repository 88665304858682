
import Flickity from 'flickity';
import 'flickity';
import 'flickity-imagesloaded';
import 'flickity-as-nav-for';
import 'flickity-fade';

// Make available globally
window.Flickity = Flickity;



