import "../modules/chartjs";


const dashboardBar = () => {
$(function() {
      // Bar chart
      const issuancePerMonth = JSON.parse(document.querySelector("#cb_nb").dataset.cb);
      new Chart(document.getElementById("chartjs-dashboard-bar-devices"), {
        type: "bar",
        data: {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "August", "September", "October", "November", "December"],
          datasets: [{
            label: "New Issues",
            backgroundColor: window.theme.primary,
            borderColor: window.theme.primary,
            hoverBackgroundColor: window.theme.primary,
            hoverBorderColor: window.theme.primary,
            data: issuancePerMonth,
            categoryPercentage: .5,
            barPercentage: .75
          }]
        },
        options: {
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              gridLines: {
                display: true
              },
              stacked: false,
              ticks: {
                stepSize: 5
              }
            }],
            xAxes: [{
              stacked: false,
              gridLines: {
                color: "transparent"
              }
            }]
          }
        }
      });
    });
}

const dashboardLine = () => {
$(function() {
      // Line chart
      const nav = document.getElementById("chartjs-dashboard-line").dataset.last;
      const navdate = document.getElementById("chartjs-dashboard-line").dataset.lastdate;
      const navs = JSON.parse(document.getElementById("chartjs-dashboard-line").dataset.navs);
      const navsdate = JSON.parse(document.getElementById("chartjs-dashboard-line").dataset.navsdate);

      // console.log(navs);
      // console.log(navsdate);


      new Chart(document.getElementById("chartjs-dashboard-line"), {
        type: "line",
        data: {
          labels: navsdate,
          datasets: [{
            label: "BG Convertible P-EUR",
            fill: true,
            backgroundColor: "transparent",
            borderColor: window.theme.primary,
            data: navs
          }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            intersect: false
          },
          hover: {
            intersect: true
          },
          plugins: {
            filler: {
              propagate: false
            }
          },
          scales: {
            xAxes: [{
              reverse: true,
              gridLines: {
                color: "rgba(0,0,0,0.05)"
              }
            }],
            yAxes: [{
              ticks: {
                stepSize: 50
              },
              display: true,
              borderDash: [5, 5],
              gridLines: {
                color: "rgba(0,0,0,0)",
                fontColor: "#fff"
              }
            }]
          }
        }
      });
    });
}

const dashboardPie = () => {
$(function() {
      // Pie chart
      new Chart(document.getElementById("chartjs-dashboard-pie"), {
        type: "pie",
        data: {
          labels: ["Risk arbitrage / Special situations", "Value with catalyst", "Credit Long/Short", "Quantitative Strategies", "Volatility Strategies"],
          datasets: [{
            data: [23, 34, 19, 8, 16],
            backgroundColor: [
              window.theme.primary,
              window.theme.warning,
              window.theme.danger,
              window.theme.info,
              "#E8EAED"
            ],
            borderColor: "transparent"
          }]
        },
        options: {
          responsive: !window.MSInputMethodContext,
          maintainAspectRatio: false,
          legend: {
            display: false
          }
        }
      });
    });
}

const chartPolar = () => {
$(function() {
      // Polar Area chart
      new Chart(document.getElementById("chartjs-polar-area"), {
        type: "polarArea",
        data: {
          labels: ["Speed", "Reliability", "Comfort", "Safety", "Efficiency"],
          datasets: [{
            label: "Model S",
            data: [35, 38, 65, 70, 24],
            backgroundColor: [
              window.theme.primary,
              window.theme.success,
              window.theme.danger,
              window.theme.warning,
              window.theme.info
            ]
          }]
        },
        options: {
          maintainAspectRatio: false
        }
      });
    });
}


const chartRadar = () => {
$(function() {
      // Radar chart
      new Chart(document.getElementById("chartjs-dashboard-radar"), {
        type: "radar",
        data: {
          labels: ["Avg Cpn", "Avg Size", "Avg Size", "Avg CP", "Avg IV"],
          datasets: [{
            label: "New Issues L3M",
            backgroundColor: "rgba(0, 123, 255, 0.2)",
            borderColor: window.theme.primary,
            pointBackgroundColor: window.theme.primary,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: window.theme.primary,
            data: [70, 53, 82, 60, 33]
          }, {
            label: "New Issues LTM",
            backgroundColor: "rgba(220, 53, 69, 0.2)",
            borderColor: window.theme.danger,
            pointBackgroundColor: window.theme.danger,
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: window.theme.danger,
            data: [35, 38, 65, 85, 84]
          }]
        },
        options: {
          maintainAspectRatio: false
        }
      });
    });
}

export { dashboardBar };
export { dashboardLine };
export { dashboardPie };
export { chartPolar };
export { chartRadar };
