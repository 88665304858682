const newsCompany = function() {
const companiesHomepage = document.querySelector("#news-feed");
const stock = document.getElementById("news-feed").dataset.name;
const domain = document.getElementById("news-feed").dataset.underlying_domain;

fetch(`https://newsapi.ai/api/v1/article/getArticles?query=%7B%22%24query%22%3A%7B%22%24and%22%3A%5B%7B%22keyword%22%3A%22${stock}%22%2C%22keywordLoc%22%3A%22body%22%7D%2C%7B%22categoryUri%22%3A%22dmoz%2FBusiness%22%7D%2C%7B%22%24or%22%3A%5B%7B%22lang%22%3A%22eng%22%7D%2C%7B%22lang%22%3A%22fra%22%7D%5D%7D%5D%7D%2C%22%24filter%22%3A%7B%22isDuplicate%22%3A%22skipDuplicates%22%7D%7D&dataType=news&dataType=pr&dataType=blog&resultType=articles&articlesSortBy=date&articlesCount=5&includeArticleImage=true&includeArticleOriginalArticle=true&articleBodyLen=-1&apiKey=c35b6c62-67a6-46bf-b400-59fceb23831f`)
  .then(response => response.json())
 .then(data => data.articles.results.forEach((result) => {
  const date = moment(result.dateTimePub).startOf('hour').fromNow();
  const publishDate = moment(result.dateTimePub).format('MMMM Do YYYY');
  const dailyCompanies = `<div class="media">
                    <a class="pr-2" href="${result.url}" target="_blank">
                    <img src="https://logo.clearbit.com/${domain}" width="46" height="46" class="rounded-circle mr-3">
                                  </a>
                    <div class="media-body">
                      <small class="float-right text-navy ml-1">${date}</small>
                      <p class="mb-2"><strong>${result.source.title}</strong> <em>${result.title}</em></p>
                      <small class="text-muted">${publishDate}</small><br />

                      <div class="media mt-3">
                        <div class="media-body">
                          <p class="text-muted">
                            ${result.body.slice(0,140)}...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />`;

  companiesHomepage.insertAdjacentHTML("beforeend", dailyCompanies);
})
  )
.catch(error => console.log('error', error));
};

export { newsCompany };
