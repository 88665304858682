// import "../modules/datatables";
import 'datatables.net';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-fixedheader';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs4';
import 'datatables.net-responsive-dt';
import 'datatables.net-dt';
import "datatables.net-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-bs4";
import "datatables.net-select";
import 'jszip';

const dataTable = () => {
  // DataTables with Column Search by Text Inputs
    $(function() {
      // Setup - add a text input to each footer cell
      $('#datatables-column-search-text-inputs tfoot th').each(function() {
        var title = $(this).text();
        $(this).html('<input type="text" class="form-control" placeholder="Search ' + title + '" />');
      });
      // DataTables
      var table = $('#datatables-column-search-text-inputs').DataTable({
      pageLength: 100,
      fixedHeader: true,
      order: [[ 8, "asc" ]]});
      // Apply the search
      table.columns().every(function() {
        var that = this;
        $('input', this.footer()).on('keyup change clear', function() {
          if (that.search() !== this.value) {
            that
              .search(this.value)
              .draw();
          }
        });
      });
    });
}

const cbIndex = () => {
  $(document).ready(function() {
    var table = $('#cbindex').DataTable( {
        responsive: true,
        paging: false,
        order: [[ 0, "desc" ]]
    } );

    new $.fn.dataTable.FixedHeader( table );
    } );
}

export { dataTable };
export { cbIndex };


